import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, InputGroup, FormControl, Button, Row, Card, CardGroup, Form } from 'react-bootstrap';
import React, { useState, useEffect, Component } from 'react';
import TastemakerImg from './Tastemakers Basic Logo.png'
import Footer from './Footer Desktop Login'
import FooterMobile from './Footer Login'
import { useNavigate, Link } from 'react-router-dom';

export default function Register() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confrimPassword, setConfirmPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [tosAgreed, setTosAgreed] = useState(false);
  const [tosAgreedDate, setTosAgreedDate] = useState(Date);
  const [tosVersion,setTosVersion] = useState('');
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handlePasswordMatch = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handleSignUp = async () => {
    try {
      // You can perform login logic here using the email and password states
      // For example, make an API request to authenticate the user
      if(confrimPassword == password){
      const signUp = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/register/', {
        method: 'POST',
        body: JSON.stringify({
          "email": email,
          "password": password,
          "userName": userName,
          "firstName": firstName,
          "lastName": lastName,
          "tosAgreed": tosAgreed,
          "tosAgreedDate": tosAgreedDate,
          "tosVersion": tosVersion
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (signUp.status === 201) {
        // Registration successful, redirect to home page
        navigate("/")
      } else {
        // Handle other status codes (400 or 500)
        const responseText = await signUp.text();
        alert(`Registration failed: ${responseText}`);
      }
    }else{
      alert('Passwords do not Match.')
    }
    } catch (error) {
      console.error('Registration error:', error);
      alert('Internal server error.');
    }
  };  

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    document.body.style.overflowX = 'hidden';

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle = {
    height: "5rem", backgroundSize: "0", backgroundColor: "black", bottom: "0",
    position: "fixed", zIndex: "1060", width: "100%", color: "white", display: "flex"
  };

  function handleSignIn(){
    navigate("/")
  }

  const handleTosCheckboxChange = (e) => {
    setTosAgreed(e.target.checked);

    if (e.target.checked) {
      // If the checkbox is checked, set the agreed date and version
      setTosAgreedDate(new Date()); // Set to the current date
      setTosVersion('1.0');
    } else {
      // If the checkbox is unchecked, clear the agreed date and version
      setTosAgreedDate(null);
      setTosVersion(null);
    }
  };

  const handleTOSLink = (e) => {
    window.open(process.env.REACT_APP_FRONTEND_URL+"/tos")
  }

  const isMobile = windowSize.width < 765

  function handleSmallerScreen() {
    return (
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: 'black' }}>
        <div style={{ width: isMobile ? "100%" : "50%", height: "100vh", overflow: "auto", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop:'2rem' }}>
          <img src={TastemakerImg} style={{ width: "80%", height: "auto" }} />
          <div style={{ display: "flex", justifyContent: 'center', alignContent: 'center', alignItems: 'center', justifyItems: "center", flexDirection: "column", marginLeft: "1rem" }}>
            <h1 style={{
              color: 'orange',
              fontFamily: 'Tenor Sans, sans-serif',
              fontSize: '5rem',
              background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>Muxelo</h1>
          </div>
          <h1 style={{color:'orange'}}>Join Now</h1>
          <div style={{ width: isMobile ? "100%" : "50%", height: isMobile ? "auto" : "", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "1rem", borderColor: "orange", padding: "1rem" }}>
            <input
              style={{ marginBottom: '.5rem' }}
              type="text"
              placeholder="Email"
              className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={handleEmailChange}
            />
            <input
              style={{ marginBottom: '.5rem' }}
              type="text"
              placeholder="Muxer Name"
              className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
              value={userName}
              onChange={handleUserNameChange}
            />
            <input
              style={{ marginBottom: '.5rem' }}
              type="password"
              placeholder="Password"
              className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
              value={password}
              onChange={handlePasswordChange}
            />
            <input
              style={{ marginBottom: '.5rem' }}
              type="password"
              placeholder="Confirm Password"
              className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
              value={confrimPassword}
              onChange={handlePasswordMatch}
            />
            <input
              style={{ marginBottom: '.5rem' }}
              type="text"
              placeholder="First Name"
              className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <input
              type="text"
              placeholder="Last Name"
              className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
              value={lastName}
              onChange={handleLastNameChange}
            />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
              <input
                type="checkbox"
                id="tosCheckbox"
                name="tosCheckbox"
                checked={tosAgreed}
                onChange={handleTosCheckboxChange}
              />
              <label htmlFor="tosCheckbox" style={{ marginLeft: '8px', color: 'white' }}>
                I am over 13 and Agree to the <Link onClick={(e) => handleTOSLink()}>Terms of Service</Link>
              </label>
            </div>
  
            <Button style={{ color: 'orange', backgroundColor: 'black', borderColor: 'orange', marginTop: '1rem' }} onClick={handleSignUp}>Sign Up</Button>
            <Button style={{ textDecoration: "underline", color: 'orange', backgroundColor: 'black', borderColor: 'black', marginTop: '1rem' }} onClick={handleSignIn}>Sign In</Button>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <div style={{height:'100vh', width:'100vw', backgroundColor:'black'}}>
      {handleSmallerScreen()}
          {/* <Footer /> */}
    </div>
  )
}

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, InputGroup, FormControl, Button, Row, Card, CardGroup, Nav } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from './login';
import Profile from './Profile';
import Home from './Home';
import Logout from './Logout';
import UserProfile from './userProfile';
import Competition from './Competition';
import Upgrade from './Upgrade';
import Feed from './Feed';
import PostSpecific from './PostSpecific';
import Register from './Register';
import { UserProvider } from './UserContext';
import UserSearch from './UserSearch';
import TOS from './TOS';
import { PushNotifications } from '@capacitor/push-notifications';
import OneSignal from 'onesignal-cordova-plugin';
// import Payment from './stripePayment';
import Ads from './Ads';

function App() {

  const [notificationId, setNotificationId] = useState(null);

    useEffect(() => {
    // Remove this method to stop OneSignal Debugging
    OneSignal.Debug.setLogLevel(6)
  
    // Replace YOUR_ONESIGNAL_APP_ID with your OneSignal App ID
    OneSignal.initialize("77fe8ca4-e82a-4355-b344-24d90cceb531");
  
    OneSignal.Notifications.addEventListener('click', async (e) => {
      let clickData = await e.notification;
      alert("Notification Clicked : " + clickData);
    })

    // OneSignal.SetLogLevel(OneSignal.LOG_LEVEL.DEBUG, OneSignal.LOG_LEVEL.DEBUG);
  
    OneSignal.Notifications.requestPermission(true).then((success) => {
      alert("Notification permission granted " + success);
    });

      // Get and save the OneSignal Player ID
      OneSignal.User.getOnesignalId().then(notificationId => {
        if (notificationId) {
          alert("OneSignal Player ID:" + notificationId);
          setNotificationId(notificationId);
        } else {
          alert("Player ID is not available yet.");
        }
      })

  // Cleanup event listener on component unmount
  return () => {
    OneSignal.Notifications.removeEventListener('click');
  };
    },[])
  

  return (
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login notificationId={notificationId}/>} />
          <Route path='/User/:id' element={<UserProfile />} />
          <Route path='/Search' element={<UserSearch />} />
          <Route path='/Account' element={<Profile />} />
          <Route path='/competition' element={<Competition />} />
          <Route path='/Upgrade' element={<Upgrade />} />
          <Route path='/Logout' element={<Logout />} />
          <Route path='/Feed' element={<Feed />} />
          <Route path='/Register' element={<Register />} />
          <Route path='/User/:id/Post/:postId' element={<PostSpecific />} />
          <Route path='/tos' element={<TOS />} />
          <Route path='*' element={<Login />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Card, Button, Col, InputGroup, FormControl, CardGroup, Modal, ButtonGroup, CardDeck, Image, Carousel, CarouselItem} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import crown from './crown.png';
import tasteMakersLogo from './Tastemakers Basic Logo.png';
import officialSpotify from './Spotify_Icon_RGB_Black.png';
// import { useNavigate } from 'react-router-dom';
import { App } from '@capacitor/app';

import { useNavigate, Link } from 'react-router-dom';

export default function Profile(){
  const navigate = useNavigate();

    const userAuthToken = sessionStorage.getItem("token");
    // var userId = sessionStorage.getItem("userId");
    var userEmail = sessionStorage.getItem("email");
    // const userImg = sessionStorage.getItem("imgURL");
    const [userPro, setUserPro] = useState(false);
    const [following, setFollowing] = useState([]);
    const followingCount = following.length;
    const [followers, setFollowers] = useState([]);
    const followersCount = followers.length;
    const [posts,setPosts] = useState([]);
    
    // const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState([]);
    const [followerIds,setFollowersIds] = useState([]);
    const [followingIds,setFollowingIds] = useState([]);
    const [backendUser, setBackendUser] = useState([]);
    const [spotifyUser, setSpotifyUser] = useState([]);
    const [userId, setUserId] = useState([]);
    

    const [isLiked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    //console.log(userImg)

    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    }, []);

    useEffect(() => {

      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
    
      window.addEventListener("resize", handleResize);
      handleResize(); // Set initial size on mount
    
      return () => window.removeEventListener("resize", handleResize);
      
    }, []);

    useEffect(() => {
      async function fetchData() {
        try {
          // Fetching register user data
          const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
          if (!registerResponse.ok) {
            throw new Error('Failed to fetch register user');
          }
          const registerData = await registerResponse.json();
          setSpotifyUser(registerData);
    
          // Fetching Spotify user data
          const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
            headers: {
               'Authorization': `Bearer ${userAuthToken}`,
               'Content-Type': 'application/json',
               }
          });
          if (!spotifyResponse.ok) {
            throw new Error('Failed to fetch Spotify user');
          }
          const spotifyUserData = await spotifyResponse.json();
          setBackendUser(spotifyUserData);   
          setUserId(spotifyUserData.userId) 
        } catch (error) {
          console.error('Error fetching data:', error);
          navigate("/");
        }
      }
    
      fetchData();
    }, []);
    useEffect(() => {
        const backButtonListener = App.addListener('backButton', (data) => {
            if (window.location.pathname === '/') {
                App.exitApp(); // Exit app if on the home page
            }else {
                  navigate(-1); // Go to the previous page
              } 
        });

        // Cleanup the listener when the component unmounts
        return () => {
            backButtonListener.remove();
        };
    }, [navigate]);
    
    useEffect(() => {
      if (!backendUser || !backendUser.userId) return;
    
      async function runUser() {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${backendUser.userId}`,{
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
          });
          const data = await response.json();
          setUserPro(data.paidMember);
          setFollowing(data.following);
          setFollowers(data.followers);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    
      runUser();
    }, [backendUser]);
    
    useEffect(() => {
      if (!backendUser || !backendUser.userId) return;
    
      async function getData() {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/getAllUserPosts/${backendUser.userId}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
          });
      
          if (!response.ok) {
            throw new Error('Failed to fetch posts');
          }
          const data = await response.json();
          //console.log(data)
          setPosts(data);
          // alert("data:" + data[0].messsage)
        } catch (error) {
          console.error('Error fetching posts:', error);
        }
      }
    
      getData();
    }, [backendUser, likes, isLiked]);
    
    useEffect(() => {
      if (!backendUser || !backendUser.userId) return;
    
      const fetchDetails = async (userIds) => {
        return Promise.all(
          userIds.map(async (userId) => {
            try {
              const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/getUserById/${userId}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                  'Content-Type': 'application/json',
                },
              });
              const data = await response.json();
              return {
                userId: data.userId, 
                following: data.following, 
                followers: data.followers, 
                userTitle: data.userTitle,
                profileImageUrl: data.profileImageUrl
                // other details if needed
              };
            } catch (error) {
              console.error('Error fetching user details:', error);
              navigate("/");
            }
          })
        );
      };

      const updateDetails = async () => {
        if (followers.length > 0) {
          const followerDetails = await fetchDetails(followers);
          setFollowersIds(followerDetails.filter(detail => detail !== null));
        }
    
        if (following.length > 0) {
          const followingDetails = await fetchDetails(following);
          setFollowingIds(followingDetails.filter(detail => detail !== null));
        }
      };
    
      updateDetails();
    }, [backendUser, followers, following]);    

    function handlePrivateBoolean(){
      if(backendUser.privateProfile == true){
        return "Private"
      }else{
        return "Public"
      }
    }

    function handleProUser(){
        if(userPro == true){
          return(
            <>
            <h1 style={{textAlign:'center'}}> <img alt='' style={{width:'2.3rem',height:'2.3rem', marginRight:'.5rem'}}src={crown}/>{backendUser.userId.replace(/^"|"$/g, '')}</h1>
            </>
          )
        }else{
            return(
              
                <h1>{backendUser.userId } <span style={{color:"white", fontSize:"2rem", textShadow: '0 0 5px #808080, 0 0 10px #808080, 0 0 15px #808080'}}>FREE</span></h1>
                )
        }
      }
      function checkForLogin(){
        if(sessionStorage.getItem("token") == null || sessionStorage.getItem("token").length < 1){
            navigate("/")
        }
        // userId = backendUser.userId
      }

      function handleCardPress(postId,userId){
       navigate('/User/' + userId + '/Post/' + postId)
    }

    const handleDeletePost = async (postId) => {
      try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/deletePost`, {
              method: 'DELETE',
              headers: {
                  'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                  postId: postId,
              })
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }else{
             navigate(0)
          }
  
          // If the delete operation was successful
          // const deleteResult = await response.json();
          // //console.log(deleteResult);
  
          // Update your state to remove the deleted comment
          // For example, you might filter out the deleted comment from your comments state
          // setComments(prevComments => prevComments.filter(comment => comment._id !== commentId));
  
      } catch (error) {
          console.error('Error deleting comment:', error);
          // Handle the error appropriately in your UI
      }
  };

  function handleHCardHeight(message){
    if(message.length > 100){
      return "18rem"
    }else{
      return "14rem"
    }
  }

  function handleUpgradeButton(){
    navigate("/Upgrade")
}

  function handlePrivateButton(userId, setting) {
    var newBool = !setting;
  
    fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/updatePrivacy`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "userId": userId,
        "privateProfile": newBool
      })
    })
    .then(response => response.json())
    .then(jsonResponse => {
      console.log(jsonResponse);
    })
    .catch(error => {
      console.error('Error handling private button:', error);
    });
    window.location.reload()
  }
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    function handleCardPressFollowing(userId){
      navigate('/User/' + userId, {state: {userId} })
    }

      const likeOrUnlikePost = async (postId, userId) => {
        try {
            // Fetch user details to get realId
            const userRealIdResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + "\"" + userId + "\"",{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const userData = await userRealIdResponse.json();

            if (!userData || !userData._id) {
                console.error('User data or user ID not found');
                return;
            }

            const realId = userData._id;
            //console.log('Real ID:', realId);

            // Fetch post details to check if realId is in the likePost array
            const postResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId,{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
            const postData = await postResponse.json();

            const likePostArray = postData.likes || [];
            const isLiked = likePostArray.some(like => like.userId === realId);

            if (isLiked) {
                // If already liked, unlike the post
                const unlikeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/unLikePost/' + postId + '/user/' + realId, {
                  method: 'DELETE',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const unlikeResult = await unlikeResponse.json();
                //console.log(unlikeResult);
            } else {
                // If not liked, like the post
                const likeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/likePost/' + postId + '/user/' + realId, {
                  method: 'POST',
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
                });
                const likeResult = await likeResponse.json();
                //console.log(likeResult);
            }

            // Update the state to trigger a re-render
            setIsLiked((prevIsLiked) => !prevIsLiked);

            setLikes(likePostArray.length);
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };

    const isMobile = windowSize.width < 765;

    
    return(
        
        <div style={{backgroundColor:"black", height:'100%', width:'100%'}}>
            {checkForLogin()}
           <div style={{width:'100%', height:'100%'}}>
            <div style={{backgroundColor:"black"}}>
                    <Navigation/>
                </div>
            <div style={{width:"100%",height:"100%", backgroundColor:"black"}}>
                
                <div style={{width:"100%", display: isMobile ?  "inline-block": "flex"}}>  
                    <div style={{width: isMobile ? "100%" : "50%", color:'white'}}>
                        <div className="justify-content-center" style={{marginTop:"6rem", textAlign:"center" }}>
                   
                <img src={backendUser.profileImageUrl || tasteMakersLogo} alt='...' style={{width:'20rem',borderRadius: "10rem",height:'20rem', marginTop:'2rem', marginBottom:'2rem'}}/>
                    
                     {handleProUser()}
                                          <h2 style={{ fontSize:'1.5rem',marginLeft:'1rem'// default color if none of the conditions are met
                        }}>
                        <span style={{color:'white'}}>Muxer</span><span style={{  color:
                            backendUser.userTitle === 'Basic' ? 'black' :
                            backendUser.userTitle === 'Seer' ? 'blue' :
                            backendUser.userTitle === 'Certified' ? 'gold' :
                            backendUser.userTitle === 'Pro' ? 'orange' :
                            backendUser.userTitle === 'Prime'? 'red':
                            'black'
                            }}> {backendUser.userTitle}</span>
                        </h2>
                     <h2>{backendUser.email}</h2>

                     <div style={{ display: "flex", justifyContent: "center", marginTop:'2rem' }}>
                        <div style={{ margin: "0 1rem" }}>
                        <h5>Following</h5>
                        <h5>{followingCount}</h5>
                        </div>
                        <div style={{ margin: "0 1rem" }}>
                        <h5>Followers</h5>
                        <h5>{followersCount}</h5>
                        </div>
                    </div>
                </div>
                    <div style={{textAlign:"center", marginTop:"1rem"}}>
                        <Button style={{color:'orange', backgroundColor:'black', borderColor:'orange'}}onClick={event => handleUpgradeButton()}>Manage Account</Button>
                    </div>
                    <div style={{textAlign:"center", marginTop:"1rem"}}>
                        <Button style={{color:'orange', backgroundColor:'black', borderColor:'orange'}}onClick={() => handlePrivateButton(backendUser.userId, backendUser.privateProfile)}>{handlePrivateBoolean()}</Button>
                    </div>
                    {backendUser.userId == "\"omazing16\"" ? 
                    <div style={{textAlign:"center", marginTop:"1rem"}}>
                    {/* <Button onClick={() => onButtonClick()}
                    style={{color:"black", backgroundColor:"white", borderColor:"black"}}>
                    <Image
                      style={{marginRight:'.5rem', width:"1.5rem", height:"1.5rem"}}
                      src={officialSpotify}
                    />
                      Connect</Button> */}
                </div>
                    : null}
                    </div> 
                    <div style={{width: isMobile ? "100%" : "50%", justifyContent:'left', alignContent:'flex-start', color:'white'}}>
                        
                        <div style={{marginBottom:'2rem', marginTop:'1rem', alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center", display:'flex',flexDirection: 'column'}}>
                  
                    {/* <h1 style={{textAlign:'center', color:"orange"}}>Feed</h1> */}
                    <div style={{marginBottom:'2rem', marginTop:'2rem', alignContent:'center', alignIems:'center', justifyContent:'center', justifyItems:"center", display:'flex',flexDirection: 'column'}}>
                    <Carousel defaultActiveIndex={1} interval={null} style={{width:"100%"}}>
                      
                    <CarouselItem  interval={null}>
                    <style>
        {`
          .carousel-control-prev,
          .carousel-control-next {
            display: none;
          }
        `}
      </style>
                    <div style={{height:'85vh', color:'white'}}>
                            <h1 style={{textAlign:'center',color: 'white', marginBottom:'0rem'}}> Following </h1>
                            <Col style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex', width: '100%' }}>
                                <Row className="flex" style={{ scrollbarColor: 'orange black',justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center", scrollbarColor: 'orange black', scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch', height: '85vh', width: '90vw', overflowX: 'hidden', overflowY: 'auto' }}>
                                {followingIds && followingIds.length > 0 ? (
                                  followingIds.map((follower) => {
                                      return(
                                          <Card onClick={() => handleCardPressFollowing(follower.userId)} key={follower._id} style={{ borderColor:'orange', display: 'inline-block', width: '100%', height: '6rem', backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }}>
                                              <CardGroup style={{display:'flex'}}>
                                                  <Card.Img src={follower.profileImageUrl || tasteMakersLogo} style={{borderRadius: '50%', marginTop:'.5rem', width: '5rem', height: '5rem' }} />
                                                  <Card style={{backgroundColor:'black', color:'white'}}>
                                                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                          <div>
                                                              <Card.Text style={{ marginLeft: '2rem', marginTop: '1rem', marginBottom:'0rem' }}>@{follower.userId.replace(/^"|"$/g, '')}</Card.Text>
                                                              <Card.Text style={{ marginLeft:'2rem' }}>
                                                                  <span style={{ color:'white' }}>Muxer </span>
                                                                  <span style={{ color:
                                                                      follower.userTitle === 'Basic' ? 'black' :
                                                                      follower.userTitle === 'Seer' ? 'cyan' :
                                                                      follower.userTitle === 'Certified' ? 'gold' :
                                                                      follower.userTitle === 'Pro' ? 'orange' :
                                                                      follower.userTitle === 'Prime' ? 'red' :
                                                                      'black'
                                                                  }}>{follower.userTitle}</span>
                                                              </Card.Text>
                                                          </div>
                                                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem', marginLeft:'1rem' }}>
                                                              <Button style={{ width: '7rem', height: '2.5rem', color:"orange", backgroundColor:"black", borderColor:"orange" }}>Remove</Button>
                                                          </div>
                                                      </div>
                                                  </Card>
                                              </CardGroup>
                                          </Card>
                                      );
                                  })
                              ) : (
                                  <Card style={{ width: '90vw', height: '50vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <h1 style={{ color: 'orange', textAlign: 'center' }}>No Following Yet!</h1>
                                  </Card>
                              )}

                           </Row>
                            </Col>
                    </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex'}}>
                            
                            <Col style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex', width: '90vw' }}>
                                <Row className="flex" style={{ alignItems:'center', justifyContent:'center',marginBottom:'4rem',scrollbarColor: 'orange black', scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch', height: '85vh', width: '70vh', overflowX: 'hidden', overflowY: 'auto' }}>
                                <h1 style={{textAlign:'center', marginBottom:'1rem'}}>My Feed</h1>
                                {posts.length > 0 ? (
                                  posts.slice().reverse().map(post => {
                                      return(
                                          <Card key={post._id} style={{ alignItems:'center', outlineColor:'orange', borderColor:'orange', display: 'inline-block', width: '90vw', height: handleHCardHeight(post.message), backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }} onClick={() => handleCardPress(post._id,post.ownerId)}>
                                              <CardGroup style={{display:"flex"}}>
                                                  <Card.Img src={post.profileImageUrl || tasteMakersLogo} style={{ borderRadius: '50%', marginTop:'1rem', width: '5rem', height: '5rem' }} />
                                                  <Card style={{ backgroundColor:'black', color:'white' }}>
                                                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                          <div>
                                                              <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom:'0rem' }}>@{post.userId.replace(/^"|"$/g, '')}</Card.Title>
                                                              <Card.Text style={{ marginLeft:'1rem' }}>
                                                                  <span style={{ color:'white' }}>Muxer </span>
                                                                  <span style={{ color:
                                                                      post.userTitle === 'Basic' ? 'black' :
                                                                      post.userTitle === 'Seer' ? 'cyan' :
                                                                      post.userTitle === 'Certified' ? 'gold' :
                                                                      post.userTitle === 'Pro' ? 'orange' :
                                                                      post.userTitle === 'Prime'? 'red' :
                                                                      'black'
                                                                  }}>{post.userTitle}</span>
                                                              </Card.Text>
                                                          </div>
                                                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
                                                          </div>
                                                      </div>
                                                  </Card>
                                                  
                                              {post.userId === userId && (
                                                  <Button 
                                                      style={{
                                                          minHeight:'1rem', 
                                                          maxHeight:'2rem', 
                                                          display:'flex',  
                                                          color:'black', 
                                                          backgroundColor:'orange', 
                                                          borderColor:'orange', 
                                                          justifyContent:"flex-end", 
                                                          marginTop:"2rem", 
                                                          marginLeft:'1rem' 
                                                      }} 
                                                      onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleDeletePost(post._id);
                                                      }}
                                                  >
                                                      Delete
                                                  </Button>
                                              )}
                                              </CardGroup>
                                              <Card style={{ color:'white', backgroundColor:'black' }}>
                                                  <Card.Body>{post.message}</Card.Body>
                                                  <Card.Text style={{ color:'white', justifyContent:"right", display:'flex' }}>
                                                      <Button style={{ color:"orange", backgroundColor:"black", borderColor:"orange", marginRight:'1rem' }} onClick={(e) => {e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId)}}>{post.likes.length} Likes</Button>
                                                      <Button style={{ color:"orange", backgroundColor:"black", borderColor:"orange" }}>{post.comments.length} Comments</Button>
                                                  </Card.Text>
                                              </Card>
                                          </Card>
                                      );
                                  })
                              ) : (
                                  <Card style={{ width: '90vw', height: '50vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <h1 style={{ color: 'orange', textAlign: 'center' }}>No Posts Yet!</h1>
                                  </Card>
                              )}

                                </Row>
                            </Col>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex'}}>
                            
                            <Col style={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex', width: '90vw' }}>
                                <Row className="flex" style={{ alignItems:'center', justifyContent:'center',marginBottom:'4rem',scrollbarColor: 'orange black', scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch', height: '85vh', width: '70vh', overflowX: 'hidden', overflowY: 'auto' }}>
                                <h1 style={{textAlign:'center', marginBottom:'1rem'}}>My Posts</h1>
                                {posts.length > 0 ? (
                                  posts.filter(post => post.userId === userId).slice().reverse().map(post => {
                                      return(
                                          <Card key={post._id} style={{ alignItems:'center', marginBottom:'.3rem', outlineColor:'orange', borderColor:'orange', display: 'inline-block', width: '90vw', height: handleHCardHeight(post.message), backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white' }} onClick={() => handleCardPress(post._id,post.ownerId)}>
                                              <CardGroup style={{display:"flex"}}>
                                                  <Card.Img src={post.profileImageUrl || tasteMakersLogo} style={{ borderRadius: '50%', marginTop:'1rem', width: '5rem', height: '5rem' }} />
                                                  <Card style={{ backgroundColor:'black', color:'white' }}>
                                                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                          <div>
                                                              <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom:'0rem' }}>@{post.userId.replace(/^"|"$/g, '')}</Card.Title>
                                                              <Card.Text style={{ marginLeft:'1rem' }}>
                                                                  <span style={{ color:'white' }}>Muxer </span>
                                                                  <span style={{ color:
                                                                      post.userTitle === 'Basic' ? 'black' :
                                                                      post.userTitle === 'Seer' ? 'cyan' :
                                                                      post.userTitle === 'Certified' ? 'gold' :
                                                                      post.userTitle === 'Pro' ? 'orange' :
                                                                      post.userTitle === 'Prime'? 'red' :
                                                                      'black'
                                                                  }}>{post.userTitle}</span>
                                                              </Card.Text>
                                                          </div>
                                                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.5rem' }}>
                                                          </div>
                                                      </div>
                                                  </Card>
                                                  
                                              {post.userId === userId && (
                                                  <Button 
                                                      style={{
                                                          minHeight:'1rem', 
                                                          maxHeight:'2rem', 
                                                          display:'flex',  
                                                          color:'black', 
                                                          backgroundColor:'orange', 
                                                          borderColor:'orange', 
                                                          justifyContent:"flex-end", 
                                                          marginTop:"2rem", 
                                                          marginLeft:'1rem' 
                                                      }} 
                                                      onClick={(e) => {
                                                          e.stopPropagation();
                                                          handleDeletePost(post._id);
                                                      }}
                                                  >
                                                      Delete
                                                  </Button>
                                              )}
                                              </CardGroup>
                                              <Card style={{ color:'white', backgroundColor:'black' }}>
                                                  <Card.Body>{post.message}</Card.Body>
                                                  <Card.Text style={{ color:'white', justifyContent:"right", display:'flex' }}>
                                                      <Button style={{ color:"orange", backgroundColor:"black", borderColor:"orange", marginRight:'1rem' }} onClick={(e) => {e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId)}}>{post.likes.length} Likes</Button>
                                                      <Button style={{ color:"orange", backgroundColor:"black", borderColor:"orange" }}>{post.comments.length} Comments</Button>
                                                  </Card.Text>
                                              </Card>
                                          </Card>
                                      );
                                  })
                              ) : (
                                  <Card style={{ width: '90vw', height: '50vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                      <h1 style={{ color: 'orange', textAlign: 'center' }}>No Posts Yet!</h1>
                                  </Card>
                              )}

                                </Row>
                            </Col>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div style={{height:'85vh', color:'white', marginBottom:'8rem'}}>
                            <h1 style={{textAlign:'center',color: 'white', marginBottom:'2rem'}}> Followers </h1>
                            <Col style={{ marginBottom:'4rem',alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center', display: 'flex', width: '100%', height:'100%' }}>
                                <Row className="flex" style={{ alignItems: 'center', scrollbarColor: 'orange black',justifyContent: 'center', justifyItems: 'center', scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch', height: '85vh', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                                {followerIds && followerIds.length > 0 ? (
                                  followerIds.map((follower) => (
                                    <Card
                                      onClick={() => handleCardPressFollowing(follower.userId)}
                                      key={follower._id}
                                      style={{
                                        marginBottom: '1rem',
                                        borderColor: 'orange',
                                        display: 'inline-block',
                                        width: '100%',
                                        height: '7rem',
                                        backgroundColor: 'black',
                                        color: 'white',
                                      }}
                                    >
                                      {/* Display user data for each follower */}
                                      <CardGroup style={{ display: 'flex' }}>
                                        <Card.Img
                                          src={follower.profileImageUrl || tasteMakersLogo}
                                          style={{
                                            borderRadius: '50%',
                                            marginTop: '.5rem',
                                            width: '5rem',
                                            height: '5rem',
                                          }}
                                        />
                                        <Card style={{ backgroundColor: 'black', color: 'white' }}>
                                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                              <Card.Text
                                                style={{ marginLeft: '2rem', marginTop: '1rem', marginBottom: '0rem' }}
                                              >
                                                @{follower.userId.replace(/^"|"$/g, '')}
                                              </Card.Text>
                                              <Card.Text style={{ marginLeft: '2rem' }}>
                                                <span style={{ color: 'white' }}>Muxer </span>
                                                <span
                                                  style={{
                                                    color:
                                                      follower.userTitle === 'Basic'
                                                        ? 'black'
                                                        : follower.userTitle === 'Seer'
                                                        ? 'cyan'
                                                        : follower.userTitle === 'Certified'
                                                        ? 'gold'
                                                        : follower.userTitle === 'Pro'
                                                        ? 'orange'
                                                        : follower.userTitle === 'Prime'
                                                        ? 'red'
                                                        : 'black',
                                                  }}
                                                >
                                                  {follower.userTitle}
                                                </span>
                                              </Card.Text>
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                marginTop: '1.5rem',
                                                marginLeft: '1rem',
                                              }}
                                            >
                                              <Button
                                                style={{
                                                  width: '7rem',
                                                  height: '2.5rem',
                                                  color: 'orange',
                                                  backgroundColor: 'black',
                                                  borderColor: 'orange',
                                                }}
                                              >
                                                Remove
                                              </Button>
                                            </div>
                                          </div>
                                        </Card>
                                      </CardGroup>
                                    </Card>
                                  ))
                                ) : (
                                  <Card style={{ width: '90vw', height: '50vh', backgroundColor: 'rgba(64, 64, 64, 0.5)', borderColor: 'transparent' }}>
                                    <h1 style={{ color: 'orange', textAlign: 'center' }}>No Followers Yet!</h1>
                                  </Card>
                                )}

                           </Row>
                            </Col>
                    </div>
                    </CarouselItem>
                    
                    </Carousel>
                    </div>
                </div>
                    </div> 
                </div>
                </div>
                
            </div>
            <div style={{marginTop:'5rem'}}>
                    <Footer/>
                </div>
            {/* <BottomGoogleAd/> */}
        </div>
    );
}
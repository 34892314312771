import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Card, Button, Col, InputGroup, FormControl, CardGroup, Modal, ButtonGroup, CardDeck, Image} from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navigation from './Navigation';
import Footer from './Footer';
import play from './play.png';
import { useNavigate, Link } from 'react-router-dom';
import Adsense from 'react-adsense';
// import SpotifyPlayback from './SpotifyPlayback';
import officialSpotify from './Spotify_Icon_RGB_Black.png'
// import BottomGoogleAd from './BottomGoogleAd';
//testing change
export default function UserProfile(){

    const [compSubmissions,setCompSubmissions] = useState([''])
    const [userPlaylists,setUserPlaylists] = useState([]);
    
    const [showSetPlayListModal, set_SetPlayList_ShowModal] = useState(false);
    const handleClose_showSetPlayListModal = () => set_SetPlayList_ShowModal(false);
    const handleShow_showSetPlayListModal = () => set_SetPlayList_ShowModal(true);

    const [showCompPlayListModal, set_CompPlayList_ShowModal] = useState(false);
    const handleClose_showCompPlayListModal = () => set_CompPlayList_ShowModal(false);
    const handleShow_showCompPlayListModal = () => set_CompPlayList_ShowModal(true);

    const [showCompPlayListModalMobile, set_CompPlayList_ShowModalMobile] = useState(false);
    const handleClose_showCompPlayListModalMobile = () => set_CompPlayList_ShowModalMobile(false);
    const handleShow_showCompPlayListModalMobile = () => set_CompPlayList_ShowModalMobile(true);

    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const [choosePlaylistActive, setChoosePlaylistActive] = useState(false);

    const [showSpotifyPlayer, setShowSpotifyPlayer] = useState(false);
    const handleClose_showSpotifyPlayer = () => setShowSpotifyPlayer(false);
    const handleOpen_showSpotifyPlayer = () => setShowSpotifyPlayer(true);

    const [isActive, setIsActive] = useState(false);
    const [isCompActive, setCompIsActive] = useState(false);
    const [currentUserForDelete, setCurrentUserForDelete] = useState([]);
    const [compDoc, setCompDoc] = useState('');
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    },[]);
    var docTracks = [];
    const [backendUser, setBackendUser] = useState([]);
    const [spotifyUser, setSpotifyUser] = useState([]);
    const [ownerId, setOwnerId] = useState('');
    const [userId, setUserId] = useState('');
    const userEmail = sessionStorage.getItem("email")

    useEffect(() => {
      async function fetchData() {
        try {
          // Fetching register user data
          const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
          if (!registerResponse.ok) {
            throw new Error('Failed to fetch register user');
          }
          const registerData = await registerResponse.json();
          setSpotifyUser(registerData);
    
          // Fetching Spotify user data
          const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
          });
    
          if (!spotifyResponse.ok) {
            throw new Error('Failed to fetch Spotify user');
          }
          const spotifyUserData = await spotifyResponse.json();
          setBackendUser(spotifyUserData);
          setOwnerId(spotifyUserData._id)
          setUserId(spotifyUserData.userId)
    
          // Now that backendUser is set, fetch posts
          //console.log("spotiy user data: "+spotifyUserData)
          // getData(spotifyUserData.userId);
    
        } catch (error) {
          console.error('Error fetching data:', error);
          navigate("/");
        }
      }
    
      fetchData();
    }, [
      // userEmail,
      //  isLiked,
      //   posts
      ])

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await fetch(process.env.REACT_APP_BACKEND_URL+'/api/competition',{
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            })
            .then(result => result.json())
            .then(data => {setCompSubmissions(data)})
            // .then(console.log(compSubmissions[0].playlistsId))
        }
        fetchUsers()
        setCurrentUserForDelete(userId)

    },[])

    useEffect(() => {
      const handleResize = () => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
    
      window.addEventListener("resize", handleResize);
      handleResize(); // Set initial size on mount
    
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    

    //Post Playlist to Competition
    async function postCompDoc() {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/competition/create', {
          method: 'POST',  // Correct placement of the method property
          body: JSON.stringify({
            userId: userId,
            playlistName: sessionStorage.getItem("playlistName"),
            playlistsId: sessionStorage.getItem("playlistId"),
            likes: []
          }),
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          }
        });
    
        if (response.ok) {
          const data = await response.json();
          //console.log(data);
        } else {
          console.error('Failed to post competition document:', response.status);
        }
      } catch (error) {
        console.error('Error posting competition document:', error);
      }
    }    
    
    function checkForUser(){
      // console.log(sessionStorage.getItem("userId"))
      if(sessionStorage.getItem("userId") != "\"omazing16\""){
        navigate("/Feed")
      }
    }

    //Get Playlist
    async function getUserPlaylist() {
      if (checkForLogin()) {
        const playlistParameters = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('spotifyToken')
          }
        };
        console.log(sessionStorage.getItem('spotifyToken'))
        try {
          const response = await fetch('https://api.spotify.com/v1/me/playlists?limit=50&mine=true', playlistParameters);
          
          if (response.ok) {
            const data = await response.json();
            const personalPlaylists = data.items.filter(playlist => playlist.owner.id === userId.replaceAll("\"", ""));
            setUserPlaylists(personalPlaylists);
          } else {
            console.error('Failed to fetch user playlists:', response.status);
          }
        } catch (error) {
          console.error('Error fetching user playlists:', error);
        }
      }
    }
    

    //Get Comp Submitted Playlist
    async function compPlaylist() {
      // console.log(sessionStorage.getItem('spotifyToken'))
      try {
        setCompIsActive(true);
        const playlistParameters = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem('spotifyToken')
          }
        };
    
        //console.log(sessionStorage.getItem('token'));
        const response = await fetch('https://api.spotify.com/v1/playlists/' + sessionStorage.getItem('compDoc'), playlistParameters);
    
        if (response.ok) {
          const data = await response.json();
          setCompDoc(data);
          //console.log(compDoc);
          //console.log("PLAYLIST TRACKS FOUND");
        } else {
          console.error('Failed to fetch competition playlist:', response.status);
        }
      } catch (error) {
        console.error('Error fetching competition playlist:', error);
      }
    }
    
    function setClickedPlaylistButton(userId, playlistsName, playlistsId) {
      let executed = false;
    
      return function () {
        if (!executed) {
          executed = true;
    
          setChoosePlaylistActive(current => !current);
    
          alert("SELECTED");
    
          sessionStorage.setItem("playlistId", playlistsId);
          sessionStorage.setItem("playlistName", playlistsName);
    
          handleClose_showSetPlayListModal();
          postCompDoc();
        }
      };
    }
    
    async function deleteButtonFunction(id) {
      try {
        //console.log(id);
    
        // Get competition data (optional)
        const getResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/competition/' + id,{
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        });
        if (getResponse.ok) {
          const getResult = await getResponse.json();
          //console.log(getResult);
        } else {
          console.error('Failed to get competition data:', getResponse.status);
        }
    
        // Delete competition
        const deleteResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/competition/', {
          method: 'DELETE',
          body: JSON.stringify({
            id: id
          }),
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        });
    
        if (deleteResponse.ok) {
          const deleteResult = await deleteResponse.json();
          //console.log(deleteResult);
        } else {
          console.error('Failed to delete competition:', deleteResponse.status);
        }
      } catch (error) {
        console.error('Error in deleteButtonFunction:', error);
      }
    }
    
      //Like Comp Playlist
      const handleLike = async (userId, id) => {
        if (!checkForLogin()) {
          return;
        }
      
        try {
          const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/competition/' + id,{
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
              'Content-Type': 'application/json',
            },
          });
          if (response.ok) {
            const data = await response.json();
            const likes = data.likes || [];
      
            if (likes.includes(userId)) {
              //console.log("REMOVED LIKE");
              await fetch(process.env.REACT_APP_BACKEND_URL + '/api/competition/removeLike', {
                method: 'PATCH',
                body: JSON.stringify({
                  userId: userId,
                  playlistId: id
                }),
                headers: {
                  'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                  'Content-Type': 'application/json',
                },
              });
              setIsActive(false);
            } else {
              //console.log("ADDED LIKE");
              await fetch(process.env.REACT_APP_BACKEND_URL + '/api/competition/addLike', {
                method: 'PATCH',
                body: JSON.stringify({
                  userId: userId,
                  playlistId: id
                }),
                headers: {
                  'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                  'Content-Type': 'application/json',
                },
              });
              setIsActive(true);
            }
          } else {
            console.error('Failed to fetch competition data:', response.status);
          }
        } catch (error) {
          console.error('Error in handleLike:', error);
        }
      };
      
    function handleNull(data){
        if(data == undefined){
            return "0"
        }else{
            return data.length
        }
    }
    function deleteButton(user,userX){
      // console.log(user)
      // console.log(currentUserForDelete)
        if(user == backendUser.userId){
            return(
            <Button style={{color:"orange",backgroundColor:"black",borderColor:"black"}}onClick={() => deleteButtonFunction(userX)}>
                Delete
            </Button>
            )
        }else{
        }
    }
    function handleCompDocPress(playlist){
      if(checkForLogin()){
      //console.log("MADE IT")
      //console.log(playlist)
      sessionStorage.setItem('compDoc', playlist)
      handleShow_showCompPlayListModal()
      compPlaylist()
      }
    }
    function handleCompDocPressMobile(playlist){
      if(checkForLogin()){
      //console.log("MADE IT")
      //console.log(playlist)
      sessionStorage.setItem('compDoc', playlist)
      handleShow_showCompPlayListModalMobile()
      compPlaylist()
      }
    }
    
function handleBigText(){
  if(windowSize.width < 750 ){
    return (
      <>
      <h1 style={{textAlign:"center",fontSize:"1.8rem", color:"orange"}}>F.O.A.T.</h1>
      <h5 style={{textAlign:"center",color:"gray", fontSize:".75rem"}}>The First of All Time</h5>
      <h5 style={{textAlign:"center",color:"gray", fontSize:".75rem",marginBottom:"1.5rem"}}>You are truly one of the first! Submit the first playlist to ever be voted for! Time to get paid!</h5>
      </>
    )
  }else{
    return(
      <>
            <h1 style={{textAlign:"center",fontSize:"8rem", color:"orange"}}>F.O.A.T.</h1>
      <h5 style={{textAlign:"center",color:"gray", fontSize:"1.75rem", color:"orange"}}>The First of All Time</h5>
      <h5 style={{textAlign:"center",color:"gray", fontSize:".75rem",marginBottom:"1.5rem", color:"orange"}}>You are truly one of the first! Submit the first playlist to ever be voted for! Time to get paid!</h5>
    </>
    )
  }
}

function handleSubmissionText(){
  if(windowSize.width < 750 ){
    return null
  }else{
    return(
      <div style={{ maxWidth:"50rem"}}>
      <h5 style={{marginBottom:"1.25rem", textAlign:"left", marginTop:'1rem', color:"orange"}}>
        Current Submissions<span style={{marginLeft:"24rem", color:"orange"}}> Payout:
      <span style={{fontSize:"3.5rem",color:"orangered"}}>$25</span></span></h5>
    </div>
    )
  }
}
function handleSubmitButton(){
  if(windowSize.width < 750 ){
    return (
      <Button style={{marginTop:".2rem",backgroundColor:"#ff914d",color:"black", borderColor:"black", justifyContent:"center", alignIems:"center", display:"flex", textAlign:"center"}} onClick={handleShow_showSetPlayListModal}>
                            Submit a Playlist
                        </Button>
    )
  }else{
    return(
      <Button style={{marginTop:"1.25rem",backgroundColor:"#ff914d",color:"black", borderColor:"black"}} onClick={handleShow_showSetPlayListModal}>
                            Submit a Playlist
                        </Button>
    )
  }
}

function handleGoogleAds(){
  if(windowSize.width < 750 ){
    return (
      <div>

        <Container style={{marginBottom:'5rem', display:"inline-flex", width:"24rem", textAlign:"center"}}>

<div style={{width:"100%"}}>
  {handleSubmissionText()}
  <Col>
    <Row className="flex overflow-auto overflow-y-scroll" style={{height:'20rem'}}>
      {compSubmissions && compSubmissions.map((user,i) => (
          <Card style={{justifyContent:"left", justifyItems:"center", height:"8rem"}} >
                  <Container style={{width:"100%"}}> 
                      <h4 style={{justifySelf:"left", marginTop:"1rem"}}>{compSubmissions[i].playlistName}</h4>
                      <Button 
                      onClick={() => handleCompDocPressMobile(compSubmissions[i].playlistsId)}
                      style={{marginLeft:"1rem",color:"black", backgroundColor:"white", borderColor:"black"}}>view
                      <Image
                        style={{marginLeft:'.5rem', width:"1.5rem", height:"1.5rem"}}
                        src={officialSpotify}
                      />
                      </Button>
                      <ButtonGroup style={{float:"right"}}>
                          <Button
                          key={compSubmissions._id} 
                          onClick={() => handleLike(userId,compSubmissions[i]._id)}
                          style={{width:"5rem",
                          backgroundColor: "white", 
                          color:"black",
                          borderColor:"orange"}}
                          >
                          Like {handleNull(compSubmissions[i].likes)}
                          </Button>
                          {deleteButton(compSubmissions[i].userId,compSubmissions[i]._id)}
                      </ButtonGroup>
                  </Container>
          </Card>
      ))}
        {/* <div> */}
        {/* {currentCards.map((card) => (
          <Card key={card.id} title={card.title} image={card.image} />
        ))}
      </div>
      {Array.from({ length: totalPages }, (_, i) => (
        <button key={i} onClick={() => setCurrentPage(i + 1)}>
          {i + 1}
        </button>
      ))} */}
      </Row>
  </Col>
  {handleSubmitButton()}
  </div>
  
</Container>
      </div>
    )
  }else{
    return(
    <div>
      {/* <Adsense.Google
      client='ca-pub-7787464840070054'
      slot='9738875136'
      style={{ width: 500, height: 300, float: 'left' }}
      format=''
  /> */}
  {/* <ins class="adsbygoogle"
  style={{display:"block", width:"20%", height:"40rem"}}
  data-ad-client="ca-pub-7787464840070054"
  data-ad-slot="9738875136"
  data-ad-format="auto"
  data-full-width-responsive="true"></ins> */}
      <Container style={{marginBottom:'5rem', display:"inline-flex", width:"100%", textAlign:"center"}}>

        <div style={{width:"100%"}}>
          {handleSubmissionText()}
          <Col>
            <Row className="flex overflow-auto overflow-y-scroll" style={{height:'25rem'}}>
              {compSubmissions && compSubmissions.map((user,i) => (
                  <Card style={{padding:".5rem",paddingBottom:"1rem", justifyContent:"left", justifyItems:"left", backgroundColor:"#FAFAFA"}} >
                          <Container> 
                              <h4 style={{justifySelf:"left"}}>{compSubmissions[i].playlistName}</h4>
                              <Button 
                              onClick={() => handleCompDocPress(compSubmissions[i].playlistsId)}
                              style={{marginLeft:"1rem",color:"black", backgroundColor:"white", borderColor:"black"}}>view
                              <Image
                        style={{marginLeft:'.5rem', width:"1.5rem", height:"1.5rem"}}
                        src={officialSpotify}
                      />
                              </Button>
                              <ButtonGroup style={{float:"right"}}>
                                  <Button
                                  key={compSubmissions._id} 
                                  onClick={() => handleLike(userId,compSubmissions[i]._id)}
                                  style={{width:"5rem",
                                  backgroundColor: "white", 
                                  color:"black",
                                  borderColor:"orange"}}
                                  >
                                  Like {handleNull(compSubmissions[i].likes)}
                                  </Button>
                                  {deleteButton(compSubmissions[i].userId,compSubmissions[i]._id)}
                              </ButtonGroup>
                          </Container>
                  </Card>
              ))}
                {/* <div> */}
                {/* {currentCards.map((card) => (
                  <Card key={card.id} title={card.title} image={card.image} />
                ))}
              </div>
              {Array.from({ length: totalPages }, (_, i) => (
                <button key={i} onClick={() => setCurrentPage(i + 1)}>
                  {i + 1}
                </button>
              ))} */}
              </Row>
          </Col>
          {handleSubmitButton()}
          </div>
          
      </Container>
{/* <div style={{maxWidth:"25%", width:"20%", height:"40rem", backgroundColor:"green", marginLeft:"1rem",marginRight:"1rem"}}>Add 2</div> */}
  {/* <ins class="adsbygoogle"
  style={{display:"block", width:"20%", height:"40rem"}}
  data-ad-client="ca-pub-7787464840070054"
  data-ad-slot="9738875136"
  data-ad-format="auto"
  data-full-width-responsive="true"></ins> */}
  </div>
    )
  }
}

  // function checkForSpotifyPlayer(){
  //   if(localStorage.getItem("showSpotifyPlayer")=="true"){
  //     // handleOpen_showSpotifyPlayer();
  //         return(
  //           <div>
  //             <SpotifyPlayback/>
  //           </div>
  //         )
  //       }
  // }

  function handleSpotifyPlayback(){
    // localStorage.setItem("showSpotifyPlayer","true")
    // console.log(localStorage.getItem("showSpotifyPlayer"))
    // checkForSpotifyPlayer()
    // Linking.openLink("https://open.spotify.com/playlist/" + sessionStorage.getItem('compDoc'))
    window.open("https://open.spotify.com/playlist/" + sessionStorage.getItem('compDoc'))
  }
  function checkForLogin(){
    // console.log(sessionStorage.getItem("token"))
    if(sessionStorage.getItem("token") == null || sessionStorage.getItem("token").length < 1){
        navigate("/");
    }else{
      return true
    }
  }

    return(
        <div style={{backgroundColor:"black"}}>
          {checkForUser()}
            <Navigation/>
            <div style={{marginTop:"6rem", width:"100%"}}>
                    {handleBigText()}
                </div>
                
                <div style={{justifyContent:"center", width:"100%",display:"inline-flex"}}>
                                        
                {handleGoogleAds()}
                    {/* Modal for Set Playlist Button */}
                <Modal show={showSetPlayListModal} onHide={handleClose_showSetPlayListModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Choose a Playlist to Submit</Modal.Title>
                    </Modal.Header>
                        <Container>
                            <Button onClick={getUserPlaylist}>
                              View Your Playlists
                            </Button>
                            <Card style={{width:'17rem'}}>
                              <Card.Body>
                                {(
                                  userPlaylists.map((userPlaylists, i) => {
                                    return (
                                      <div>
                                        <div>
                                        <Container>
                                          <Card key={userPlaylists._id}>
                                          {/* <Card.Img src={userPlaylists.images[0].url}/> */}
                                          {/* <Card.Img src={playlistImg(userPlaylists.images[0].url)}/> */}
                                            <Card.Title>{userPlaylists.name}</Card.Title>
                                          </Card>

                                          <Button 
                                          style={{backgroundColor: !choosePlaylistActive ? 'green' : 'red'}}
                                          onClick={setClickedPlaylistButton(userId,userPlaylists.name,userPlaylists.id)}>
                                          Submit
                                          </Button>
                                        </Container>  
                                        </div>
                                        
                                      </div>
                                    )
                                  })
                                  )}
                              </Card.Body>
                              
                        </Card>
                        </Container>
                        
                    
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose_showSetPlayListModal}>
                        Close
                    </Button>
                    <Button variant="secondary">
                        Set Playlist
                    </Button>
                    </Modal.Footer>
                </Modal>
                {/* Show submitted playlist tracks */}
                   <Modal show={showCompPlayListModal} onHide={handleClose_showCompPlayListModal} style={{padding:"5rem",zIndex:"1050"}}>
                    <Modal.Header closeButton>
                    <Modal.Title>{compDoc.name}<Button style={{marginLeft:"6rem", backgroundColor:"white", color:"black", borderColor:"black", fontSize:"1.25rem"}} onClick={() => handleSpotifyPlayback()}>
                      Play
                      <Image
                        style={{marginLeft:'.5rem', width:"2rem", height:"2rem"}}
                        src={officialSpotify}
                      />
                      </Button></Modal.Title>
                    </Modal.Header>
                    <Container style={{paddingTop:"1rem",paddingBottom:"1rem"}}>
                    {isCompActive && compDoc.href ? (compDoc.tracks.items.map((items,i) => {
                      return(
                        <Card>
                      <CardGroup>
                      
                      <Card.Img src={items.track.album.images[0].url} style={{marginLeft:"1rem", borderRadius:"0rem",maxWidth:"5rem",maxHeight:"5rem"}}/>
                      {/* {console.log(items.track.album.images[0].url)} */}
                      <CardGroup as='div' className='flex-column' style={{maxWidth:"20rem", paddingLeft:"1rem", paddingTop:".08rem"}}>
                        <Card.Title>
                        {items.track.name}
                        </Card.Title>
                        <Card.Text>
                        {items.track.artists[0].name}
                        </Card.Text>  
                         </CardGroup>
                       </CardGroup>       
                    </Card>
                      )
                    })) : null}
                    

                    </Container>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose_showCompPlayListModal}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>

{/* FOR MOBILE - MODAL */}
<Modal show={showCompPlayListModalMobile} onHide={handleClose_showCompPlayListModalMobile} style={{width:"24rem", marginLeft:".25rem"}}>
                    <Modal.Header closeButton>
                    <Modal.Title>{compDoc.name}
                    <Button style={{marginLeft:"1rem",backgroundColor:"white", color:"black", borderColor:"black"}} onClick={() => handleSpotifyPlayback()}>
                      Play
                      <Image
                        style={{marginLeft:'.5rem', width:"1.5rem", height:"1.5rem"}}
                        src={officialSpotify}
                      />
                      </Button>
                      </Modal.Title>
                    </Modal.Header>
                    <Container style={{paddingTop:"1rem",paddingBottom:"1rem"}}>
                    {isCompActive && compDoc.href ? (compDoc.tracks.items.map((items,i) => {
                      return(
                        <Card>
                      <CardGroup style={{display:"flex"}}>
                      
                      <Card.Img src={items.track.album.images[0].url} style={{width:"5rem",height:"5rem",borderRadius:"0rem", marginLeft:"1rem"}}/>
                      {/* {console.log(items.track.album.images[0].url)} */}
                      <CardGroup as='div' className='flex-column' style={{maxWidth:"20rem", paddingLeft:"1rem", paddingTop:".08rem"}}>
                        
                        <Card.Title>
                        {items.track.name}
                        </Card.Title>
                        <Card.Text>
                        {items.track.artists[0].name}
                        </Card.Text>  
                         </CardGroup>
                       </CardGroup>       
                    </Card>
                      )
                    })) : null}
                    

                    </Container>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose_showCompPlayListModalMobile}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
                    
                </div>
                {/* {checkForSpotifyPlayer()} */}
                {/* <BottomGoogleAd/> */}
                <Footer/>
        </div>
    )
}
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms of Service for Muxelo</h1>
      <p>
        <strong>Last Updated:</strong> [2/7/2024]
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using Muxelo, users agree to be bound by these terms and conditions. These terms may be updated
        periodically without prior notice.
      </p>

      <h2>2. User Accounts</h2>
      <p>
        Users must provide accurate information when creating an account. Accounts must not be used for illegal or
        unauthorized purposes.
      </p>

      <h2>3. User Conduct</h2>
      <p>
        Users are responsible for all activity on their account. Prohibited activities include harassment, spreading hate
        speech, and posting illegal content.
      </p>

      <h2>4. Content Ownership and Rights</h2>
      <p>
        Users retain ownership of the content they post but grant Muxelo a license to use, distribute, and display this
        content. Muxelo respects intellectual property rights and expects users to do the same.
      </p>

      <h2>5. Privacy Policy</h2>
      <p>User data will be handled as outlined in Muxelo's Privacy Policy.</p>

      <h2>6. Service Availability</h2>
      <p>
        Muxelo does not guarantee uninterrupted or error-free service. Muxelo reserves the right to modify or discontinue
        the service with or without notice.
      </p>

      <h2>7. Third-Party Links and Services</h2>
      <p>
        Muxelo may contain links to third-party websites or services, which are not owned or controlled by Muxelo.
        Muxelo is not responsible for the content, policies, or practices of third-party websites or services.
      </p>

      <h2>8. Removal of Account and Content</h2>
      <p>
        Muxelo reserves the right to remove an account or delete content that violates the terms of service. Users are
        responsible for complying with these terms, and failure to do so may result in account removal or content deletion.
      </p>

      <h2>9. Limitation of Liability</h2>
      <p>
        Muxelo is not liable for any indirect, incidental, special, consequential or punitive damages resulting from the
        use of the service.
      </p>

      <h2>10. Governing Law</h2>
      <p>These terms shall be governed by and interpreted in accordance with the laws of the jurisdiction in which Muxelo operates.</p>

      <h2>11. Changes to Terms</h2>
      <p>Muxelo reserves the right to modify these terms at any time.</p>

      <h2>12. Contact Information</h2>
      <p>For any questions about these terms, please contact muxelo.llc@gmail.com</p>
    </div>
  );
};

export default TermsAndConditions;

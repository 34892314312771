import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, InputGroup, FormControl, Button, Row, Card, CardGroup, Form } from 'react-bootstrap';
import React, { useState, useEffect, Component } from 'react';
import TastemakerImg from './Tastemakers Basic Logo.png'
import Footer from './Footer Desktop Login'
import FooterMobile from './Footer Login'
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { Input, Typography } from "@material-tailwind/react";

export default function Login({notificationId}) {
  useEffect(() => {
    if (notificationId) {
        console.log("Received notification ID:", notificationId);
    }
}, [notificationId]);

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [backendUser, setBackendUser] = useState([]);
  const [spotifyUser, setSpotifyUser] = useState([]);
  const [backendId, setBackendId] = useState([]);
  const { setUser } = useUser();
  const navigate = useNavigate();
  
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUserInfo = async () => {
    try {
      const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${email}`);
      if (!registerResponse.ok) {
        throw new Error('Failed to fetch register user');
      }
      const registerData = await registerResponse.json();
      setSpotifyUser(registerData);
 
      const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
          'Content-Type': 'application/json',
        },
      });
 
      if (!spotifyResponse.ok) {
        throw new Error('Failed to fetch Spotify user');
      } 
      const spotifyUserData = await spotifyResponse.json();
      setBackendId(spotifyUserData._id);
 
      // Return the backendId once set
      return spotifyUserData._id;
 
    } catch (error) {
      console.error("Error fetching user info", error);
    }
  };
 

  const handleLogin = async () => {
    try {
      // You can perform login logic here using the email and password states
      // For example, make an API request to authenticate the user
      alert("Here's the endpoint:" + process.env.REACT_APP_BACKEND_URL)
      const login = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/login/authenticate', {
        method: 'POST',
        body: JSON.stringify({
          "email": email,
          "password": password
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (login.status === 200) {
        alert("login working")
        const responseJson = await login.json();
        localStorage.setItem('user', JSON.stringify({ email: email, token: responseJson.token }));
        sessionStorage.setItem("email", email)
        sessionStorage.setItem('token', responseJson.token)
        updateNotificationId(notificationId)
        navigate("/Feed")
      } else if (login.status === 401 || login.status === 500) {
        // Handle unauthorized (401) or server error (500)
        const responseText = await login.text();
        alert(`Login failed: ${responseText}`);
      }else if(login.status === 404){
        const responseText = await login.text();
        alert(`System failed: ${responseText}`);
      }else{
        const responseText = await login.text();
        alert(`System failed: ${responseText}`);
      }
    } catch (error) {
      console.error('Login error:', error);
      alert("Error: " + error);
      alert(email + ", " + process.env.REACT_APP_BACKEND_URL)
      alert('Internal server error - Try Again.');
    }
  };  

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    document.body.style.overflowX = 'hidden';

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerStyle = {
    height: "5rem", backgroundSize: "0", backgroundColor: "black", bottom: "0",
    position: "fixed", zIndex: "1060", width: "100%", color: "white", display: "flex"
  };

    function handleRegister(){
    navigate("/Register")
  }
  const isMobile = windowSize.width < 765

  const updateNotificationId = async (notificationId) => {
    try {
      const userBackendId = await handleUserInfo(); // Wait for the backendId from handleUserInfo
      alert("id:" + userBackendId)
      // Proceed with the fetch request using the obtained backendId
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/update/' + userBackendId, {
        method: 'PATCH',
        body: JSON.stringify({
          notificationId: notificationId
        }),
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        alert("NotificationId updated for User");
      } else {
        alert("Failed to update Notification ID");
      }
    } catch (error) {
      alert("Failed to Update Notification ID: " + error);
    }
  };
  

  function handleSmallerScreen() {
      return (
        <div style={{ height: "100vh", width: "100%", display: "inline-block" }}>
          <div style={{ height: "100%", width: "100%", backgroundColor: 'black', display: isMobile ? "inline-block" : "flex" }}>
          <div style={{ width: isMobile ? "100%" : "50%", height: isMobile ? "70%" : "100%",display: isMobile ? "inline-block" : "flex", justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center" }}>
            {isMobile ? "" : 
                 <img src={TastemakerImg} style={{ width: isMobile ? "65%" : "100%", height: "auto" }} />
              }
            <div style={{ display: "flex", flexDirection: "column", marginLeft: "1rem", justifyContent:'center', alignContent:'center', alignItems: 'center', justifyItems:"center" }}>
              {isMobile ? <img src={TastemakerImg} style={{ width: isMobile ? "65%" : "100%", height: "auto" }} /> : 
                 ""
              }
              <h1 style={{
                color: 'orange',
                fontFamily: 'Tenor Sans, sans-serif',
                fontSize: '5rem',
                background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: isMobile ? '5rem' : '6rem'
              }}>Muxelo</h1>
              { isMobile ? "" :
              <h2 style={{ fontSize: "1.5rem", color:'darkorange' }}>Get Paid to Play(List)</h2>
              }
            </div>
          </div>

            <div style={{ width: "100%", height: isMobile ? "30%" : "", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "1rem" }}>
              <h1 style={{marginBottom: isMobile ? '1rem' : "",color: 'orange',background: `-webkit-linear-gradient(45deg, #ff4500, #ffa500)`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent', }}>{isMobile ? "" : "Log In"}</h1>
              <input
                style={{ marginBottom: '1rem' }}
                type="text"
                placeholder="Email"
                className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
                value={email}
                onChange={handleEmailChange}
              />
              <input
                type="password"
                placeholder="Password"
                className="bg-transparent text-white rounded px-3 py-2 outline-none focus:ring-2 focus:ring-blue-500"
                value={password}
                onChange={handlePasswordChange}
              />
              <Button style={{color: 'orange', backgroundColor:'black', borderColor:'orange', marginTop:'1rem', marginBottom:'1rem'}} onClick={handleLogin}>Login</Button>
              <Button style={{textDecoration:'underline',color: 'orange', backgroundColor:'black', borderColor:'black'}} onClick={handleRegister}>Register</Button>
            </div>
          </div>
        </div>
      )
  }

  return (
    <div>
      {handleSmallerScreen()}
      
      <Footer />
    </div>
  )
}

import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Container, Row, Card, Button, Col, InputGroup, FormControl, CardGroup, Modal, ButtonGroup, CardDeck, Image, Carousel, CarouselItem, ModalDialog, ModalBody} from 'react-bootstrap';
import Navigation from './Navigation';
import Footer from './Footer';
import tasteMakersLogo from './Tastemakers Basic Logo.png'
import { App } from '@capacitor/app';
import { useNavigate, Link } from 'react-router-dom';


export default function PostSpecific(){
    // Get the current URL
    // const currentUrl = process.env.REACT_APP_FRONTEND_URL;

    // // Split the URL by '/'
    // const urlParts = currentUrl.split('/');

    // // Find the index of 'User' and 'Post' in the URL
    // const userIndex = urlParts.indexOf('User');
    // const postIndex = urlParts.indexOf('Post');

    // // Extract userId and postId based on their positions in the URL
    // const userId = urlParts[userIndex + 1];
    // const postId = urlParts[postIndex + 1];
    const location = useLocation();
    const { userId, postId } = location.state || {};

    const currentUserId = sessionStorage.getItem('userId')
    const userImg = sessionStorage.getItem("imgURL");

    //console.log("User ID:", userId);
    //console.log("Post ID:", postId);

    const [handleState, setHandleState ]= useState(false);
    const [post, setPost] = useState([]);
    const [comments,setComments] = useState([]);
    const [isLiked, setIsLiked] = useState(false);
    const [likes, setLikes] = useState(0);
    const [backendUser, setBackendUser] = useState([]);
    const [spotifyUser, setSpotifyUser] = useState([]);
    const userEmail = sessionStorage.getItem("email")
    const navigate = useNavigate();

    const [showTextarea, setShowTextarea] = useState(false);

    const handleShowTextarea = () => {
        setShowTextarea(true);
    };

    const handleHideTextarea = () => {
        setShowTextarea(false);
    };
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
      }, []);

      useEffect(() => {

        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
      
        window.addEventListener("resize", handleResize);
        handleResize(); // Set initial size on mount
      
        return () => window.removeEventListener("resize", handleResize);
        
      }, []);

    const [commentText, setCommentText] = useState(''); // State to store comment text

    useEffect(() => {
        const backButtonListener = App.addListener('backButton', (data) => {
            if (window.location.pathname === '/') {
                App.exitApp(); // Exit app if on the home page
            }else {
                  navigate(-1); // Go to the previous page
              } 
        });

        // Cleanup the listener when the component unmounts
        return () => {
            backButtonListener.remove();
        };
    }, [navigate]);

    useEffect(() => {
        async function fetchData() {
          try {
            // Fetching register user data
            const registerResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/register/${userEmail}`);
            if (!registerResponse.ok) {
              throw new Error('Failed to fetch register user');
            }
            const registerData = await registerResponse.json();
            setSpotifyUser(registerData);
            //console.log(registerData); // Debugging (remove if sensitive)
        
            // Fetching Spotify user data
            const spotifyResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + '\"' + registerData.userName + '\"', {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                'Content-Type': 'application/json',
              },
            });
        
            if (!spotifyResponse.ok) {
              throw new Error('Failed to fetch Spotify user');
            }
            const spotifyUserData = await spotifyResponse.json();
            // //console.log(spotifyUserData)
            setBackendUser(spotifyUserData);
        
          } catch (error) {
            console.error('Error fetching data:', error);
            // Handle errors appropriately
          }
        }
      
        fetchData();
      },[])

    useEffect(() => {
        function fetchData() {
            const checkUser = fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/getUserById/' + userId, {
                method:"GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok: ' + response.status);
                }
                return response.json();
              });
          
            const checkPost = fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId, {
                method:'GET',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok: ' + response.status);
                }
                return response.json();
              })
          
            // Use Promise.all to wait for both promises to resolve
            Promise.all([checkUser, checkPost])
              .then(([userData, postData]) => {
                // Both requests were successful
                //console.log('User data:', userData);
                //console.log('Post data:', postData);
          
                // Set handleRender to true
                setHandleState(true);
                setPost(postData)
                setComments(postData.comments)
              })
              .catch(error => {
                // Handle errors for either checkUser or checkPost
                console.error('Error fetching data:', error);
                // You might want to handle errors or show messages here
              });
          }
          
          // Call the fetchData function
          fetchData();
      }, [isLiked, commentText, comments.length]); 

      const likeOrUnlikePost = async (postId, userId) => {
        try {
            // Fetch user details to get realId
            const userRealIdResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/users/' + userId,{
                method:"GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            });
            const userData = await userRealIdResponse.json();

            if (!userData || !userData._id) {
                console.error('User data or user ID not found');
                return;
            }

            const realId = userData._id;
            //console.log('Real ID:', realId);

            // Fetch post details to check if realId is in the likePost array
            const postResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/getPost/' + postId, {
                method:"GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json',
                  },
            });
            const postData = await postResponse.json();

            const likePostArray = postData.likes || [];
            const isLiked = likePostArray.some(like => like.userId === realId);

            if (isLiked) {
                // If already liked, unlike the post
                const unlikeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/unLikePost/' + postId + '/user/' + realId, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                      },
                });
                const unlikeResult = await unlikeResponse.json();
                //console.log(unlikeResult);
            } else {
                // If not liked, like the post
                const likeResponse = await fetch(process.env.REACT_APP_BACKEND_URL + '/api/posts/likePost/' + postId + '/user/' + realId, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                        'Content-Type': 'application/json',
                      },
                });
                const likeResult = await likeResponse.json();
                //console.log(likeResult);
            }

            // Update the state to trigger a re-render
            setIsLiked((prevIsLiked) => !prevIsLiked);

            setLikes(likePostArray.length);
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };

    const handlePostComment = async (postID) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/addComment`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    postId: postID,
                    userId: backendUser.userId,
                    text: commentText,
                    profileImageUrl: backendUser.profileImageUrl
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const postResult = await response.json();
            //console.log(postResult);
    
            // Clear the comment text and hide the textarea
            setCommentText('');
            setShowTextarea(false);
    
            // Update comments or other related states if necessary
    
        } catch (error) {
            console.error('Error posting comment:', error);
            // Handle the error appropriately in your UI
        }
    };    

    const remainingCharacters = 200 - commentText.length;

    const handleDeleteComment = async (commentId, postId, userId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/posts/deleteComment`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    postId: postId,
                    userId: userId,
                    commentId: commentId,
                })
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            // If the delete operation was successful
            const deleteResult = await response.json();
            //console.log(deleteResult);
    
            // Update your state to remove the deleted comment
            // For example, you might filter out the deleted comment from your comments state
            setComments(prevComments => prevComments.filter(comment => comment._id !== commentId));
    
        } catch (error) {
            console.error('Error deleting comment:', error);
            // Handle the error appropriately in your UI
        }
    };

    function handleCardImgPress(userId){
        navigate('/User/' + userId)
    }

    const isMobile = windowSize.width < 765

      function determinePage(){
        if(handleState == true){
            return(
                <div style={{height:'100vh', width:'100%', backgroundColor:'black'}}>
            <Navigation/>
            <div style={{marginTop:'6rem', height:'110vh', width:'100%', backgroundColor:'black', color:'orange', display:'flex', justifyContent:'center'}}>
                <Card style={{width: isMobile ? '98%' : '50%', height:'98%', margin:'auto',borderColor:'orange', backgroundColor:'black', borderBottomColor:'orange'}}>
                <Card style={{backgroundColor:'black', borderBottomColor:'orange', height:'35%', width:'100%', justifyContent:'center'}}>
                <CardGroup style={{ display: 'flex', alignItems: 'center' }}>
                    <Card.Img style={{ width: '8rem', height: '8rem', marginLeft: '1rem', marginTop: '1rem', borderRadius:'50%' }} src={post.profileImageUrl || tasteMakersLogo} 
                    onClick={(e) => {
                        e.stopPropagation();
                        handleCardImgPress(post.userId)
                    }}
                    alt="User Img" />
                    <CardGroup style={{ display: 'flex', flexDirection: 'column' }}>
                    <Card.Title style={{ marginLeft: '1rem', marginTop: '2rem', marginBottom: '.25rem', color: 'white', fontSize:'2rem'}}>@{post.userId.replace(/['"]+/g, '')}</Card.Title>
                    <Card.Subtitle style={{ fontSize:'1.5rem',marginLeft:'1rem'// default color if none of the conditions are met
                        }}>
                        <span style={{color:'white'}}>Muxer </span><span style={{  color:
                            post.userTitle === 'Basic' ? 'black' :
                            post.userTitle === 'Seer' ? 'blue' :
                            post.userTitle === 'Certified' ? 'gold' :
                            post.userTitle === 'Pro' ? 'orange' :
                            post.userTitle === 'Prime'? 'red':
                            'black'
                            }}>{post.userTitle}</span>
                        </Card.Subtitle>
                    </CardGroup>
                </CardGroup>
                <Card.Text style={{ fontSize:'1.20rem',marginLeft: '2rem', marginTop: '1rem', color: 'white' }}>{post.message}</Card.Text>
                <Card.Text style={{color:'white', display:'flex', justifyContent:'right'}}> <Button 
                onClick={(e) => {e.stopPropagation(); likeOrUnlikePost(post._id, backendUser.userId)}}
                style={{color:"orange", backgroundColor:'black', borderColor:'orange', marginRight:'1rem'}}>{post.likes.length} Likes  </Button> <Button style={{color:"orange", backgroundColor:'black', borderColor:'orange', marginRight:'1rem'}}> {post.comments.length} Comments </Button> </Card.Text>
                </Card>
                <Card style={{ height: '63%', width: '95%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', backgroundColor: 'black' }}>
                <CardGroup style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Card.Title style={{ color: 'white', marginBottom: '1rem', marginTop: '1rem', fontSize: '1.75rem', width: '90%', textAlign: 'center' }}>Comments</Card.Title>
                <Button style={{color:'orange', backgroundColor:'black', borderColor:'orange', marginBottom:'1rem'}}onClick={handleShowTextarea}>Add</Button>
            </CardGroup>
            {showTextarea && (
                <div style={{ position: 'relative' }}>
                    <textarea
                        rows="4" // You can adjust the number of rows as needed
                        cols="100" // You can adjust the number of columns as needed
                        placeholder="Type your comment here..."
                        style={{ width: '100%', backgroundColor: 'black', color: 'white',borderRadius:'10px' }} // Adjust styles as needed
                        value={commentText}
                        onChange={(e) => {
                            if (e.target.value.length <= 200) {
                                setCommentText(e.target.value);
                            }
                        }}
                        maxLength="200" // Restrict to 200 characters
                    ></textarea>
                    <p
                        style={{
                            position: 'absolute',
                            bottom: '5px',
                            right: '5px',
                            color: 'white',
                        }}
                    >
                        Remaining characters: {remainingCharacters}
                    </p>
                    <Button style={{marginBottom:'.5rem',color:'orange', backgroundColor:'black', borderColor:'orange'}} onClick={(e) => handlePostComment(post._id)}>Post</Button>
                </div>
            )}
                <Row className="flex" style={{ scrollbarColor: 'orange black', scrollbarWidth: 'thin', WebkitOverflowScrolling: 'touch', height: '100%', width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                {comments.slice().reverse().map(comment => {
                return (
                    <Card key={comment._id} style={{ width:'100%',height:"14rem", color: "white", backgroundColor: 'black', borderColor: 'orange', marginBottom: '1rem', alignSelf: 'flex-start' }}>
                    <CardGroup style={{ justifyContent: 'space-between', alignItems: 'center', display:'flex'}}>
                        <Card.Img src={comment.profileImageUrl || tasteMakersLogo} style={{ marginTop: '.5rem', width: '5rem', height: '5rem', borderRadius:'50%' }} />
                        <Card.Title style={{ color: 'white', marginTop: '2rem', marginLeft: '1rem' }}>@{comment.userId}</Card.Title>
                        <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
                        {comment.userId === backendUser.userId && ( // Display delete button only if the comment is made by the logged-in user
                            <Button style={{color:'black', backgroundColor:'orange', borderColor:'orange'}} onClick={() => handleDeleteComment(comment._id, post._id, backendUser.userId)}>Delete</Button>
                        )}
                        </div>
                    </CardGroup>
                    <Card style={{ backgroundColor: 'black', color: 'white' }}>
                        <Card.Body style={{ color: 'white' }}>{comment.text}</Card.Body>
                    </Card>
                    </Card>
                );
                })}

                </Row>
                </Card>


                </Card>
            </div>
            <Footer/>
        </div>
            )
        }else if(handleState == false){
            return(
                <div style={{height:'100%', width:'100%'}}>
            <Navigation/>
            <div style={{marginTop:'6rem', height:'100vh', width:'100%', backgroundColor:'black', color:'orange'}}>
                404
            </div>
            <Footer/>
        </div>
            )
        }
      }
      
    return(
        <div style={{height:"100%"}}>
        {determinePage()}
        </div>
    )
}